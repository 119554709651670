.admin-container input {
  padding: 4px;
  width: 230px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #e1e0e0;
  border-radius: 4px;
  text-align: center;
}

.admin-add-monster-submit, .admin-edit-monster-submit {
  width: 50px;
  margin: 5px;
  border-radius: 4px;
}

.admin-container input.number-input {
  width: 50px;
}

.add-monster-container {
  display: flex;
  flex-direction: column;
}

.edit-monster-row, .edit-armour-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.admin-edit-monster-table {
  width: 50%;
}

.admin-edit-armour-table, .admin-edit-weapon-table, .admin-edit-world-table, .admin-add-news-table {
  width: 100%;
}

.admin-edit-monster-table, .admin-edit-monster-table th {
  text-align: center;
}

.admin-edit-world-table, .admin-edit-world-table th,
.admin-edit-armour-table, .admin-edit-armour-table th,
.admin-edit-weapon-table, .admin-edit-weapon-table th {
  text-align: center;
}

.admin-edit-monster-table input.input-monster-edit-name {
  width: 300px;
}

.admin-display-error-message {
  color: red;
}

.admin-display-success-message {
  color: green;
}

.admin-container input.input-world-text-input {
  width: 110px;
}

.admin-container input.input-news-text-input {
  width: 90%;
  margin-left: 25px;
}

.admin-container input.input-world-number-input {
  width: 35px;
}

.admin-container input.input-armour-text-input,
.admin-container input.input-weapon-text-input {
  width: 140px;
}

.admin-container .input-is-special-portal, .admin-container .input-align-change {
  font-weight: bold;
  margin: 10px;
}

.admin-add-news-table th {
  text-align: center;
}

.admin-add-news-table td.admin-news-character {
  vertical-align: top;
}

.admin-news-hide, .admin-news-type {
  text-align: center;
}

.admin-news-hide-header, .admin-news-type-header {
  width: 60px;
}