.register-container input[type=text], .register-container input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #e1e0e0;
  border-radius: 4px;
}

.register-container button {
  background-color: #625d5d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}

.register-container button:hover {
  background-color: #4d4b4b;
}

.register-container button:hover {
  opacity: 0.8;
}

.register-container .cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #9b271e;
}

.register-container .cancelbtn:hover {
  background-color: #75150d;
}

.register-container {
  padding: 20px;
  background-color: #bdbdbd;
}

.register-container span.psw {
  float: right;
  padding-top: 16px;
}

.register-container.register-footer {
  background-color: #cdcdcd;
}

.psw a {
  cursor: pointer;
}

.psw a:hover {
  text-decoration: none;
}