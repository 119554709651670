.btn-margin {
  margin: 7px 3px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  padding-top: 30px;
  width: auto;
  max-width: 1260px;
}

.navbar.navbar-default {
  background-color: #b9b9b9;
  border: 1px solid black;
}

.login-wrapper {
  position: absolute;
  right: 10px;
  display: inline-block;
}

.login-wrapper button, .login-wrapper button:active, .login-wrapper button:focus {
  background-color: #625d5d;
  border-color: #625d5d;
}

.login-wrapper button:hover {
  background-color: #4d4b4b;
}

body {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2rem;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.long-button {
  width: 100%;
}

.collapsed-section {
  height: 0;
  overflow: hidden;
}
