.chat-messagebox {
  border-top: 1px solid black;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
  order: 3;
  flex: 1;
}

.chat-links {
  order: 1;
  color: red;
}

.chat-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.chat-messages { 
  margin-bottom: auto;
  align-self: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  order: 2;
  flex-grow: 1;
  overflow-wrap: break-word;
}

.chat-messagebox-wrapper {
  margin-top: auto;
  width: 100%;
}

.chat-input {
  background-color: #cfcfcf;
  height: 25px;
  border-radius: 3px;
  border: 1px solid black;
  padding: 0 4px;
  width: 75%;
}

.chat-input::placeholder {
  line-height: 2.5rem;
}

.chat-send-button, .chat-send-button:hover {
  background-color: #cfcfcf;
  border: 1px solid black;
  height: 25px;
}