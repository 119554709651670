.village-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.village-column {
  flex-direction: column;
  flex: 1;
}

.village-section {
  margin-bottom: 20px;
}

.village-section-header {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 5px;
}

.village-section a {
  color: #000000;
  font-weight: 500;
}

.village-navigation {
  width: 100%;
  display: flex;
}

.gem-shop-link {
  cursor: pointer;
}

.gem-shop-link:hover {
  color: #540d0d;
}

.gem-shop-actions {
  margin-bottom: 10px;
  margin-top: 5px;
}

.gem-shop-item:hover {
  cursor: pointer;
  color: #540d0d;
}

.gem-shop-can-afford {
  color: green;
}

.gem-shop-not-afford {
  color: red;
}

.gem-shop-link-active {
  color: brown;
}

.gem-shop-base-efficiency.clickable:hover {
  cursor: pointer;
  color: #540d0d;
  font-weight: bold;
}

.gem-shop-wrapper {
  margin-top: 25px;
}

.gem-shop-text {
  margin-bottom: 20px;
}

.upgrade-efficiency-panel, .enchant-panel {
  position: absolute;
  background-color: #90908d;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  z-index: 5;
  width: fit-content;
  white-space: nowrap;
  margin-left: -40px;
}

.gem-shop-base-efficiency-wrapper {
  display: inline-block;
}

.gem-shop-enchant-wrapper {
  display: inline-block;
}

.gem-shop-enchant-link {
  color: green;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.gem-shop-enchant-no-link {
  color: red;
  font-weight: bold;
  font-size: 1.2em;
}

.gem-shop-message {
  margin-bottom: 15px;
}

.gem-shop-enhance-picker, .gem-shop-enhance-picker-wrapper {
  display: inline-block;
}

.gem-shop-enhance-picker-item, .gem-shop-cancel-link {
  display: inline-block;
}

.gem-shop-enhance-picker-item:hover, .gem-shop-cancel-link:hover {
  cursor: pointer;
  color: #540d0d;
}