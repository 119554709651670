.outworld-map-tile {
  width: 16px;
  height: 20px;
  justify-content: center;
  display: flex;
  border: 1px solid #5d5d58;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
  background-color: #b9b9b9;
}

.outworld-map-tile.left-edge {
  border-left: 2px solid red;
}
.outworld-map-tile.right-edge {
  border-right: 2px solid red;
}
.outworld-map-tile.top-edge {
  border-top: 2px solid red;
}
.outworld-map-tile.bottom-edge {
  border-bottom: 2px solid red;
}

.outworld-map {
  height: 140px;
  width: 112px;
  border: 1px solid #5d5d58;
  display: flex;
  flex-wrap: wrap;
}

.outworld-map-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pk-tile {
  background-color: #d40300;
}

.portal-tile {
  background-color: #33348f;
}

.outworld-land-claim-takeover {
  margin: 3px;
}

.outworld-wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-direction: row;
  border: 1px solid black;
}

.outworld-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.outworld-right-column {
  flex: 0 0 180px;
  max-width: 180px;
}

.outworld-compass-map-wrapper {
  display: flex;
  flex-direction: row;
}

.outworld-status-message {
  border: 1px solid black;
  margin-bottom: 5px;
}

.outworld-compass {
  width: 75px;
}

.outworld-compass-horizontal {
  display: flex;
}

.outworld-compass-north, .outworld-compass-south {
  text-align: center;
}

.outworld-compass-west {
  text-align: left;
  width: 50%;
}

.outworld-compass-east {
  text-align: right;
  width: 50%;
}

.outworld-compass-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3px;
}

.outworld-compass-link a {
  color: black;
}

.outworld-compass-link a:hover {
  cursor: pointer;  
  color: #3a3a3a;
  text-decoration: none;
}

.outworld-top-row {
  order: 1;
}

.outworld-middle-row {
  order: 2;
  min-height: 123px;
}

.outworld-bottom-row {
  order: 3;
  min-height: 60px;
}

.outworld-row {
  display: flex;
}

.outworld-tile {
  border: 1px solid black;
}

.outworld-description-actions-content {
  width: 100%;
}

.outworld-description-actions-wrapper {
  display: flex;
  height: 124px;
}

.outworld-actions-wrapper {
  flex: 1;
  padding: 5px;
}

.outworld-actions-wrapper a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.outworld-portals-players {
  padding: 3px;
  flex-grow: 1;
  width: 100%;
  min-height: 120px;
}

.outworld-takeover-settings,
.outworld-land-information {
  width: 100%;
  height: 88px;
}

.outworld-monsters-barrels  {
  width: 100%;
  flex-grow: 1;
}

.outworld-no-mobs {
  color: green;
}

.outworld-mobs-here {
  margin-bottom: 15px;
  color: green;
  flex-grow: 1;
}

.outworld-little-guy {
  height: 16px;
  width: 8px;
  background-image:  url('data:image/gif;base64,R0lGODlhCQAQAPcKACYhJzMzMz8/PwAzUABCcEZASFZRV3ZweABbmgBksAAAAACNmgCJ4AGW/yGu/wDO4EG6/0H9/2fA/5mZmb+/v4/Z/6/C/6/i/9fP2f///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAAoALAAAAAAJABAAAAhgABUIHEhQ4IQJBRVMwHDgQMEJFA4AKHigAAADAxEgkIBgwQONChBAsHBBggOQIitIUIkSggQJECC0jAkhAsiQDnImQJCRgYMGDwT0ZMDgQYChRY8K1NgA6MSBBKIOGBgQADs=')
}

.outworld-land-portals-header, .outworld-world-portals-header {
  font-weight: bold;
  margin-top: 10px;
}

.outworld-players-here-header {
  font-weight: bold;
}

.outworld-players-here {
  min-height: 40px;
}

.outworld-land-teleporter-link, .outworld-world-teleporter-link, .outworld-players-here-player {
  color: #2e2e99;
}

.outworld-land-portal-links {
  overflow: hidden;
  white-space: nowrap;
}

.outworld-players-here-level {
  color: #620a0a;
}

.outworld-land-description-title {
  color: #222294;
  font-size: 1.2em;
}

.outworld-land-description-description {
  color: green;
  font-size: 1em;
}

.outworld-land-description-sub_text {
  font-size: 0.9em;
}

.outworld-compass .status-message-success, .outworld-compass .status-message-fail {
  text-align: center;
}

.outworld-compass-message-wrapper {
  margin: auto;
}

.outworld-fight-monster {
  color: 2f5e87;
  cursor: pointer;

}

.outworld-fight-log-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.outworld-fight-log-row {
  display: flex;
}

.outworld-fight-log-player-attack {
  order: 1;
  width: 50%;
  border-right: 1px solid black;
  padding: 5px;
}

.outworld-fight-log-monster-attack {
  order: 2;
  width: 50%;
  padding: 5px;
}

.outworld-fight-loot-wrapper {
  text-align: center;
}

.outworld-fight-outcome {
  color: green;
  font-weight: bold;
}

.outworld-fight-result-gold {
  color: gold;
}

.outworld-fight-result-experience {
  color: #459b8e;
}

.outworld-battle-stats {
  margin: 1rem 0;
}

.outworld-battle-mob-stats,
.outworld-battle-character-stats {
  font-size: 1rem;
}

.outworld-fight-leveled {
  color: #660404;
  margin-top: 1rem;
}

.outworld-land-option-input {
  background-color: #cfcfcf;
  height: 25px;
  border-radius: 3px;
  border: 1px solid black;
  padding: 0 4px;
  width: 67%;
  margin-top: 5px;
  margin-left: 5px;
}

.outworld-land-option-confirm {
  color: green;
  cursor: pointer;
}

.outworld-land-option-cancel {
  color: red;
  cursor: pointer;
}

.outworld-land-input-wrapper {
  margin-top: 10px;
}

.outworld-land-option-pk-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -10px;
}

.outworld-land-options-wrapper, .outworld-land-owner-options-wrapper, .outworld-land-owner-options, .outworld-land-input-wrapper {
  height: 100%;
  padding-left: 1px;
}

.outworld-land-options-flex-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.outworld-land-options-flex-row {
  flex-direction: row;
  display: flex;
}

.outworld-land-options-flex-column {
  flex: 1;
}

.outworld-land-owner-options button {
  font-size: 0.9em;
  cursor: pointer;
}

.outworld-fight-item-drop {
  color: #661a1a;
  margin: 1rem 0;
}

.outworld-loot-drop {
  font-size: 1.2em;
}

.outworld-fight-drop-ruby {
  color: red;
}

.outworld-fight-drop-emerald {
  color: #025a02;
}

.outworld-fight-drop-diamond {
  color: pink;
}

.outworld-fight-drop-crystal {
  color: #04c3c3;
}

.outworld-fight-drop-key {
  color: gold;
}

.outworld-fight-drop-topaz {
  color: rgb(93, 81, 9);
}

.outworld-fight-drop-garnet {
  color: rgb(109, 11, 11);
}

.outworld-fight-drop-amethyst {
  color: rgb(80, 16, 190);
}

.outworld-fight-drop-tourmaline {
  color: rgb(30, 59, 1);
}

.outworld-fight-drop-aquamarine {
  color: rgb(5, 40, 144);
}

.outworld-fight-drop-opal {
  color: rgb(133, 118, 134);
}

.outworld-fight-drop-sapphire {
  color: rgb(0, 13, 161);
}

.outworld-fight-loot-drop {
  margin-top: 5px;
}

.outworld-loot-barrel-drop {
  color: brown;
}

.outworld-tile.outworld-monsters-barrels {
  display: flex;
}

.outworld-tile.outworld-monsters-barrels .outworld-monster-section {
  flex: 1;
  flex-grow: 1;
}

.outworld-barrel {
  color: brown;
  font-weight: bold;
  margin: 5px;
  cursor: pointer;
}

.outworld-barrel-section {
  padding: 5px;
}

.outworld-item-drop {
  margin-top: 5px;
}

.outworld-item-drop-name {
  color: #001efc;
  font-size: 1.2em;
  font-weight: bold;
}

.outworld-monster-list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.mob-left {
  flex: 1 0 40%;
}

.mob-right {
  flex: 1 0 50%;
  justify-content: flex-start;
}

.outworld-item-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 5px;
}

.outworld-action-panel {
  height: 100%;
  margin-bottom: 5px;
}

.outworld-button {
  color: #337ab7;
}

.outworld-compass-button:hover {
  font-weight: bold;
}

.outworld-status-message .eat-message {
  color: green;
  padding-left: 2px;
  padding-top: 2px;
}

.outworld-status-message .eat-message.bad-action {
  color: red;
}

.outworld-mob-wrapper {
  display: flex;
  flex-direction: row;
}

.outworld-mob-filter-input {
  background-color: #a4aaa8;
  font-weight: bold;
  width: 30px;
  font-size: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.outworld-mob-filter-save-button {
  background-color: #a4aaa8;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 2px;
}

.battle-wrapper {
  margin: 0.5rem;
}

.battle-timer-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.battle-timer-wrapper {
  text-align: center;
  flex-grow: 1;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 2.5;
  vertical-align: middle;
}

.battle-action-button-wrapper {
  flex: 1;
  text-align: center;
}
