.back-button-wrapper {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  margin-top: 10px;
}

.back-button {
  color: black;
}

.back-button:hover {
  color: #3a3a3a;
  text-decoration: none;
  cursor: pointer;
}