.navigation-links {
  margin-top: 5px;
  background-color: #b9b9b9;
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: auto;
  align-self: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  order: 2;
  flex-grow: 1;
  overflow-wrap: break-word;
}

.navigation-links a {
  color: #000000;
  font-weight: 500;
}

.navigation-links .bolded {
  font-weight: bolder;
}

.navigation-links a.link-is-active {
  font-weight: bolder;
  color: #720707;
  outline : none;
  text-decoration: underline;
}

.links-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
