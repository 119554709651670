.game-wrapper {
  background: #d2d3d7;
  display: flex;
  flex-flow: row wrap;
}

.game-wrapper > * {
  flex: 1 100%;
}

.game-footer {
  background: #b9b9b9;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 5px;
  padding: 10px;
}

.game-main {
  margin: 0 5px;
  text-align: left;
  height: 80vh;
  background: #b9b9b9;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.game-nav {
  height: 80vh;
  overflow-y: hidden;
}

.game-chat {
  background: #b9b9b9;
  padding: 5px;
  height: 80vh;
  border: 1px solid black;
  border-radius: 4px;
}

@media all and (min-width: 600px) {
  .game-nav {
    flex: 0 0 185px;
  }
  .game-chat {
    flex: 0 0 240px;
  }
}

@media all and (min-width: 800px) {
  .game-main    { flex: 3 0px; }
  .game-nav { order: 1; }
  .game-main    { order: 2; }
  .game-chat { order: 3; }
  .game-footer  { order: 4; }
}

.underlined {
  text-decoration: underline;
}

.staff {
  font-weight: bold;
}

.staff-1 {
  color: #09b1b1;
}

.staff-2 {
  color: #3e0262;
}

.staff-3 {
  color: #f5f520;
}

.staff-4 {
  color: #d10000;
}

.user-name {
  color: black;
}

.life-safe {
  color: #3b6535;
}

.life-warn {
  color: #f5f520;
}

.life-danger {
  color: #b1400c;
  font-weight: bold;
}

.life-critical {
  color: #ff0000;
  font-weight: bolder;
}

.health {
  display: inline-block;
}

.user-experience {
  color: #3b6535;
}

.align-negative {
  color: #681717
}

.align-positive {
  color: #00ffe4;
}

.user-level {
  color: #3b6535
}

.bolded {
  font-weight: bold;
}

.underlined {
  text-decoration: underline;
}

.status-message-success {
  color: #3b6535;
}

.status-message-fail {
  color: #c80707;
}

.status-message-container {
  height: 18px;
  margin: 5px 0;
}

.game-input {
  background-color: #cfcfcf;
  height: 25px;
  border-radius: 3px;
  border: 1px solid black;
  padding: 0 4px;
  width: 250px;
}

.game-button, .game-button:active {
  background-color: #cfcfcf;
  border: 1px solid black;
  height: 25px;
  margin-top: 9px;
  border-radius: 4px;
}

.game-button:active, .game-button:focus {
  background-color: #cfcfcf;
  border: 1px solid #9e0303;
}

.game-button:hover {
  background-color: #c0bfbf;
  border: 1px solid black;
}

#withdrawButton {
  margin-left: 0;
}

.outworld-event-wrapper {
  outline: 0;
}

.keys-bound.outworld-event-wrapper:focus {
  border: 1px solid red;
}

.inventory-equipped-item {
  color: #9f1818;
}

.inventory-equipment-header {
  margin-top: 5px;
  font-weight: bold;
  text-decoration: underline;
}

.inventory-equipment-link {
  cursor: pointer;
}

.inventory-equipment-link:hover {
  color: #540d0d;
}

.inventory-message {
  height: 20px;
}

.inventory-equipment-section-header {
  background-color: rgb(165, 126, 126);
  border: 1px solid black;
  font-weight: bold;
  text-align: center;
  padding: 2px;
  margin: 5px 0;
}

.stats-stat {
  padding-left: 5px;
}

.stats-stats-header, .stats-equipment-header {
  margin: 5px 0;
}

.stats-character-stats-header, .stats-other-items-header, .stats-clan-header, .stats-house-header {
  margin-top: 15px;
  margin-bottom: 5px;
}

.stats-page-wrapper {
  display: flex;
  flex-direction: row;
}

.stats-left-column {
  order: 1;
  flex: 1;
}

.stats-right-column {
  order: 2;
  flex: 1;
}

.stats-equipment-name-value {
  color: #5f1414;
}

.stats-equipment-type {
  font-weight: bold;
}

.online-character-row {
  border-bottom: 1px solid black;
}

.news-item {
  margin: 10px 0;
}

.news-item-type {
  font-weight: bold;
}

.news-header {
  font-weight: bold;
  font-size: 1.1em;
}

.inventory-item-enhancements {
  padding-left: 5px;
  display: inline-block;
  color: black;
}

.inventory-enhancement-line {
  display: inline-block;
}

.inventory-enhancement-value {
  text-shadow: 0 0 1px white;
  font-size: 1.2em;
}
.inventory-enhancement-value-1 { color: #907900; }
.inventory-enhancement-value-2 { color: #3b6535; }
.inventory-enhancement-value-3 { color: #dcffc0; }
.inventory-enhancement-value-4 { color: red; }
.inventory-enhancement-value-5 { color: white; }
.inventory-enhancement-value-6 { color: blue; }
.inventory-enhancement-value-7 { color: #4a4b46; }
.inventory-enhancement-value-8 { color: #ba00ff; }

.inventory-enhancement-line {
  position: relative;
}

.ud-tooltip {
  position: absolute;
  margin-top: -40px;
  text-transform: uppercase;
  background-color: #90908d;
  border-radius: 4px;
  padding: 10px;
}

.item-enhancement-wrapper {
  display: inline-block;
}

.enhancement-action-panel {
  position: absolute;
  background-color: #90908d;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  z-index: 5;
  width: fit-content;
  white-space: nowrap;
  margin-left: -40px;
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
}

.account-reset-me-warning {
  color: red;
}

.account-display-message {
  margin-bottom: 5px;
}

.account-error-message {
  color: red;
}

.account-success-message {
  color: green;
}

.profile-tabs, .inventory-tabs {
  display: flex;
}

.profile-tab, .inventory-tab {
  cursor: pointer;
  flex: 1;
  text-align: center;
  padding: 5px;
  background-color: gray;
  margin-bottom: 10px;
  color: black;
}

.active-tab {
  background-color: #7d4545;
  font-weight: bold;
  border: 1px solid black;
}

.selected {
  color: #720707;
}

.account-referrals-content {
  display: flex;
}

.account-referrals-generate {
  flex: 1;
}

.account-referrals-list {
  width: 80%;
}

.account-active-referral-code {
  display: inline-block;
}

.workout-stats-wrapper {
  margin-top: 1rem;
}

.workout-total-stats {
  color: green;
  margin-top:10px;
}

.workout-total-stats-error {
  color: red;
}

.workout-stat-input {
  background-color: #cfcfcf;
  border-radius: 3px;
  width: 5rem;
}
