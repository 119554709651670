.login-modal {
  max-width: 30%;
  background-color: #dededd;
}

.login-overlay {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6)
}

.login-modal-flex-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-modal-tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border: 1px solid black;
  height: 50px;
  background-color: #625d5d;
}

.login-modal-tab {
  flex: 1;
  font-size: 1.4em;
  margin: auto;
  cursor: pointer;
}

.login-modal-tab-left {
  border-right: 1px solid black;
}

.login-modal-tab-right {
  border-left: 1px solid black;
}

.login-modal-tab-clicked {
  background-color: #552727;
  color: black;
  font-weight: bold;
}