.userinfo-wrapper {
  border: 1px solid black;
  background-color: #b9b9b9;
  padding: 5px;
  border-radius: 4px;
}

.info-header {
  margin-bottom: 10px;
}

.user-clock-wrapper {
  border: 1px solid black;
  background-color: #b9b9b9;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
}