body {
  margin: 0;
  padding: 0;
  background-color: #dedede;
}

.btn-margin {
  margin: 7px 3px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  padding-top: 30px;
  width: auto;
  max-width: 1260px;
}

.navbar.navbar-default {
  background-color: #b9b9b9;
  border: 1px solid black;
}

.login-wrapper {
  position: absolute;
  right: 10px;
  display: inline-block;
}

.login-wrapper button, .login-wrapper button:active, .login-wrapper button:focus {
  background-color: #625d5d;
  border-color: #625d5d;
}

.login-wrapper button:hover {
  background-color: #4d4b4b;
}

body {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2rem;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.long-button {
  width: 100%;
}

.collapsed-section {
  height: 0;
  overflow: hidden;
}

.login-modal {
  max-width: 30%;
  background-color: #dededd;
}

.login-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6)
}

.login-modal-flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-modal-tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border: 1px solid black;
  height: 50px;
  background-color: #625d5d;
}

.login-modal-tab {
  flex: 1 1;
  font-size: 1.4em;
  margin: auto;
  cursor: pointer;
}

.login-modal-tab-left {
  border-right: 1px solid black;
}

.login-modal-tab-right {
  border-left: 1px solid black;
}

.login-modal-tab-clicked {
  background-color: #552727;
  color: black;
  font-weight: bold;
}
.login-container input[type=text], .login-container input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #e1e0e0;
  border-radius: 4px;
}

.login-container button {
  background-color: #625d5d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}

.login-container button:hover {
  background-color: #4d4b4b;
}

.login-container button:hover {
  opacity: 0.8;
}

.login-container .cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #9b271e;
}

.login-container .cancelbtn:hover {
  background-color: #75150d;
}

.login-container {
  padding: 20px;
  background-color: #bdbdbd;
}

.login-container span.psw {
  float: right;
  padding-top: 16px;
}

.login-container.login-footer {
  background-color: #cdcdcd;
}

.psw a {
  cursor: pointer;
}

.psw a:hover {
  text-decoration: none;
}

.login-message {
  color: red;
  text-align: center;
  padding: 5px 0;
  background-color:#552727;
}
.register-container input[type=text], .register-container input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #e1e0e0;
  border-radius: 4px;
}

.register-container button {
  background-color: #625d5d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}

.register-container button:hover {
  background-color: #4d4b4b;
}

.register-container button:hover {
  opacity: 0.8;
}

.register-container .cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #9b271e;
}

.register-container .cancelbtn:hover {
  background-color: #75150d;
}

.register-container {
  padding: 20px;
  background-color: #bdbdbd;
}

.register-container span.psw {
  float: right;
  padding-top: 16px;
}

.register-container.register-footer {
  background-color: #cdcdcd;
}

.psw a {
  cursor: pointer;
}

.psw a:hover {
  text-decoration: none;
}
.header-links {
  /* TODO: Use flex to position */
  display: inline-block;
  right: 100px;
  top: 15px;
  position: absolute;
}
.chat-messagebox {
  border-top: 1px solid black;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
  order: 3;
  flex: 1 1;
}

.chat-links {
  order: 1;
  color: red;
}

.chat-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.chat-messages { 
  margin-bottom: auto;
  align-self: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  order: 2;
  flex-grow: 1;
  overflow-wrap: break-word;
}

.chat-messagebox-wrapper {
  margin-top: auto;
  width: 100%;
}

.chat-input {
  background-color: #cfcfcf;
  height: 25px;
  border-radius: 3px;
  border: 1px solid black;
  padding: 0 4px;
  width: 75%;
}

.chat-input::-webkit-input-placeholder {
  line-height: 2.5rem;
}

.chat-input::-ms-input-placeholder {
  line-height: 2.5rem;
}

.chat-input::placeholder {
  line-height: 2.5rem;
}

.chat-send-button, .chat-send-button:hover {
  background-color: #cfcfcf;
  border: 1px solid black;
  height: 25px;
}
.userinfo-wrapper {
  border: 1px solid black;
  background-color: #b9b9b9;
  padding: 5px;
  border-radius: 4px;
}

.info-header {
  margin-bottom: 10px;
}

.user-clock-wrapper {
  border: 1px solid black;
  background-color: #b9b9b9;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
}
.navigation-links {
  margin-top: 5px;
  background-color: #b9b9b9;
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: auto;
  align-self: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  order: 2;
  flex-grow: 1;
  overflow-wrap: break-word;
}

.navigation-links a {
  color: #000000;
  font-weight: 500;
}

.navigation-links .bolded {
  font-weight: bolder;
}

.navigation-links a.link-is-active {
  font-weight: bolder;
  color: #720707;
  outline : none;
  text-decoration: underline;
}

.links-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.back-button-wrapper {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  margin-top: 10px;
}

.back-button {
  color: black;
}

.back-button:hover {
  color: #3a3a3a;
  text-decoration: none;
  cursor: pointer;
}
.admin-container input {
  padding: 4px;
  width: 230px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #e1e0e0;
  border-radius: 4px;
  text-align: center;
}

.admin-add-monster-submit, .admin-edit-monster-submit {
  width: 50px;
  margin: 5px;
  border-radius: 4px;
}

.admin-container input.number-input {
  width: 50px;
}

.add-monster-container {
  display: flex;
  flex-direction: column;
}

.edit-monster-row, .edit-armour-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.admin-edit-monster-table {
  width: 50%;
}

.admin-edit-armour-table, .admin-edit-weapon-table, .admin-edit-world-table, .admin-add-news-table {
  width: 100%;
}

.admin-edit-monster-table, .admin-edit-monster-table th {
  text-align: center;
}

.admin-edit-world-table, .admin-edit-world-table th,
.admin-edit-armour-table, .admin-edit-armour-table th,
.admin-edit-weapon-table, .admin-edit-weapon-table th {
  text-align: center;
}

.admin-edit-monster-table input.input-monster-edit-name {
  width: 300px;
}

.admin-display-error-message {
  color: red;
}

.admin-display-success-message {
  color: green;
}

.admin-container input.input-world-text-input {
  width: 110px;
}

.admin-container input.input-news-text-input {
  width: 90%;
  margin-left: 25px;
}

.admin-container input.input-world-number-input {
  width: 35px;
}

.admin-container input.input-armour-text-input,
.admin-container input.input-weapon-text-input {
  width: 140px;
}

.admin-container .input-is-special-portal, .admin-container .input-align-change {
  font-weight: bold;
  margin: 10px;
}

.admin-add-news-table th {
  text-align: center;
}

.admin-add-news-table td.admin-news-character {
  vertical-align: top;
}

.admin-news-hide, .admin-news-type {
  text-align: center;
}

.admin-news-hide-header, .admin-news-type-header {
  width: 60px;
}
.village-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.village-column {
  flex-direction: column;
  flex: 1 1;
}

.village-section {
  margin-bottom: 20px;
}

.village-section-header {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 5px;
}

.village-section a {
  color: #000000;
  font-weight: 500;
}

.village-navigation {
  width: 100%;
  display: flex;
}

.gem-shop-link {
  cursor: pointer;
}

.gem-shop-link:hover {
  color: #540d0d;
}

.gem-shop-actions {
  margin-bottom: 10px;
  margin-top: 5px;
}

.gem-shop-item:hover {
  cursor: pointer;
  color: #540d0d;
}

.gem-shop-can-afford {
  color: green;
}

.gem-shop-not-afford {
  color: red;
}

.gem-shop-link-active {
  color: brown;
}

.gem-shop-base-efficiency.clickable:hover {
  cursor: pointer;
  color: #540d0d;
  font-weight: bold;
}

.gem-shop-wrapper {
  margin-top: 25px;
}

.gem-shop-text {
  margin-bottom: 20px;
}

.upgrade-efficiency-panel, .enchant-panel {
  position: absolute;
  background-color: #90908d;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  z-index: 5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  margin-left: -40px;
}

.gem-shop-base-efficiency-wrapper {
  display: inline-block;
}

.gem-shop-enchant-wrapper {
  display: inline-block;
}

.gem-shop-enchant-link {
  color: green;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.gem-shop-enchant-no-link {
  color: red;
  font-weight: bold;
  font-size: 1.2em;
}

.gem-shop-message {
  margin-bottom: 15px;
}

.gem-shop-enhance-picker, .gem-shop-enhance-picker-wrapper {
  display: inline-block;
}

.gem-shop-enhance-picker-item, .gem-shop-cancel-link {
  display: inline-block;
}

.gem-shop-enhance-picker-item:hover, .gem-shop-cancel-link:hover {
  cursor: pointer;
  color: #540d0d;
}
.outworld-map-tile {
  width: 16px;
  height: 20px;
  justify-content: center;
  display: flex;
  border: 1px solid #5d5d58;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
  background-color: #b9b9b9;
}

.outworld-map-tile.left-edge {
  border-left: 2px solid red;
}
.outworld-map-tile.right-edge {
  border-right: 2px solid red;
}
.outworld-map-tile.top-edge {
  border-top: 2px solid red;
}
.outworld-map-tile.bottom-edge {
  border-bottom: 2px solid red;
}

.outworld-map {
  height: 140px;
  width: 112px;
  border: 1px solid #5d5d58;
  display: flex;
  flex-wrap: wrap;
}

.outworld-map-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pk-tile {
  background-color: #d40300;
}

.portal-tile {
  background-color: #33348f;
}

.outworld-land-claim-takeover {
  margin: 3px;
}

.outworld-wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-direction: row;
  border: 1px solid black;
}

.outworld-column {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.outworld-right-column {
  flex: 0 0 180px;
  max-width: 180px;
}

.outworld-compass-map-wrapper {
  display: flex;
  flex-direction: row;
}

.outworld-status-message {
  border: 1px solid black;
  margin-bottom: 5px;
}

.outworld-compass {
  width: 75px;
}

.outworld-compass-horizontal {
  display: flex;
}

.outworld-compass-north, .outworld-compass-south {
  text-align: center;
}

.outworld-compass-west {
  text-align: left;
  width: 50%;
}

.outworld-compass-east {
  text-align: right;
  width: 50%;
}

.outworld-compass-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3px;
}

.outworld-compass-link a {
  color: black;
}

.outworld-compass-link a:hover {
  cursor: pointer;  
  color: #3a3a3a;
  text-decoration: none;
}

.outworld-top-row {
  order: 1;
}

.outworld-middle-row {
  order: 2;
  min-height: 123px;
}

.outworld-bottom-row {
  order: 3;
  min-height: 60px;
}

.outworld-row {
  display: flex;
}

.outworld-tile {
  border: 1px solid black;
}

.outworld-description-actions-content {
  width: 100%;
}

.outworld-description-actions-wrapper {
  display: flex;
  height: 124px;
}

.outworld-actions-wrapper {
  flex: 1 1;
  padding: 5px;
}

.outworld-actions-wrapper a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.outworld-portals-players {
  padding: 3px;
  flex-grow: 1;
  width: 100%;
  min-height: 120px;
}

.outworld-takeover-settings,
.outworld-land-information {
  width: 100%;
  height: 88px;
}

.outworld-monsters-barrels  {
  width: 100%;
  flex-grow: 1;
}

.outworld-no-mobs {
  color: green;
}

.outworld-mobs-here {
  margin-bottom: 15px;
  color: green;
  flex-grow: 1;
}

.outworld-little-guy {
  height: 16px;
  width: 8px;
  background-image:  url('data:image/gif;base64,R0lGODlhCQAQAPcKACYhJzMzMz8/PwAzUABCcEZASFZRV3ZweABbmgBksAAAAACNmgCJ4AGW/yGu/wDO4EG6/0H9/2fA/5mZmb+/v4/Z/6/C/6/i/9fP2f///wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAAoALAAAAAAJABAAAAhgABUIHEhQ4IQJBRVMwHDgQMEJFA4AKHigAAADAxEgkIBgwQONChBAsHBBggOQIitIUIkSggQJECC0jAkhAsiQDnImQJCRgYMGDwT0ZMDgQYChRY8K1NgA6MSBBKIOGBgQADs=')
}

.outworld-land-portals-header, .outworld-world-portals-header {
  font-weight: bold;
  margin-top: 10px;
}

.outworld-players-here-header {
  font-weight: bold;
}

.outworld-players-here {
  min-height: 40px;
}

.outworld-land-teleporter-link, .outworld-world-teleporter-link, .outworld-players-here-player {
  color: #2e2e99;
}

.outworld-land-portal-links {
  overflow: hidden;
  white-space: nowrap;
}

.outworld-players-here-level {
  color: #620a0a;
}

.outworld-land-description-title {
  color: #222294;
  font-size: 1.2em;
}

.outworld-land-description-description {
  color: green;
  font-size: 1em;
}

.outworld-land-description-sub_text {
  font-size: 0.9em;
}

.outworld-compass .status-message-success, .outworld-compass .status-message-fail {
  text-align: center;
}

.outworld-compass-message-wrapper {
  margin: auto;
}

.outworld-fight-monster {
  color: 2f5e87;
  cursor: pointer;

}

.outworld-fight-log-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.outworld-fight-log-row {
  display: flex;
}

.outworld-fight-log-player-attack {
  order: 1;
  width: 50%;
  border-right: 1px solid black;
  padding: 5px;
}

.outworld-fight-log-monster-attack {
  order: 2;
  width: 50%;
  padding: 5px;
}

.outworld-fight-loot-wrapper {
  text-align: center;
}

.outworld-fight-outcome {
  color: green;
  font-weight: bold;
}

.outworld-fight-result-gold {
  color: gold;
}

.outworld-fight-result-experience {
  color: #459b8e;
}

.outworld-battle-stats {
  margin: 1rem 0;
}

.outworld-battle-mob-stats,
.outworld-battle-character-stats {
  font-size: 1rem;
}

.outworld-fight-leveled {
  color: #660404;
  margin-top: 1rem;
}

.outworld-land-option-input {
  background-color: #cfcfcf;
  height: 25px;
  border-radius: 3px;
  border: 1px solid black;
  padding: 0 4px;
  width: 67%;
  margin-top: 5px;
  margin-left: 5px;
}

.outworld-land-option-confirm {
  color: green;
  cursor: pointer;
}

.outworld-land-option-cancel {
  color: red;
  cursor: pointer;
}

.outworld-land-input-wrapper {
  margin-top: 10px;
}

.outworld-land-option-pk-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -10px;
}

.outworld-land-options-wrapper, .outworld-land-owner-options-wrapper, .outworld-land-owner-options, .outworld-land-input-wrapper {
  height: 100%;
  padding-left: 1px;
}

.outworld-land-options-flex-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.outworld-land-options-flex-row {
  flex-direction: row;
  display: flex;
}

.outworld-land-options-flex-column {
  flex: 1 1;
}

.outworld-land-owner-options button {
  font-size: 0.9em;
  cursor: pointer;
}

.outworld-fight-item-drop {
  color: #661a1a;
  margin: 1rem 0;
}

.outworld-loot-drop {
  font-size: 1.2em;
}

.outworld-fight-drop-ruby {
  color: red;
}

.outworld-fight-drop-emerald {
  color: #025a02;
}

.outworld-fight-drop-diamond {
  color: pink;
}

.outworld-fight-drop-crystal {
  color: #04c3c3;
}

.outworld-fight-drop-key {
  color: gold;
}

.outworld-fight-drop-topaz {
  color: rgb(93, 81, 9);
}

.outworld-fight-drop-garnet {
  color: rgb(109, 11, 11);
}

.outworld-fight-drop-amethyst {
  color: rgb(80, 16, 190);
}

.outworld-fight-drop-tourmaline {
  color: rgb(30, 59, 1);
}

.outworld-fight-drop-aquamarine {
  color: rgb(5, 40, 144);
}

.outworld-fight-drop-opal {
  color: rgb(133, 118, 134);
}

.outworld-fight-drop-sapphire {
  color: rgb(0, 13, 161);
}

.outworld-fight-loot-drop {
  margin-top: 5px;
}

.outworld-loot-barrel-drop {
  color: brown;
}

.outworld-tile.outworld-monsters-barrels {
  display: flex;
}

.outworld-tile.outworld-monsters-barrels .outworld-monster-section {
  flex: 1 1;
  flex-grow: 1;
}

.outworld-barrel {
  color: brown;
  font-weight: bold;
  margin: 5px;
  cursor: pointer;
}

.outworld-barrel-section {
  padding: 5px;
}

.outworld-item-drop {
  margin-top: 5px;
}

.outworld-item-drop-name {
  color: #001efc;
  font-size: 1.2em;
  font-weight: bold;
}

.outworld-monster-list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.mob-left {
  flex: 1 0 40%;
}

.mob-right {
  flex: 1 0 50%;
  justify-content: flex-start;
}

.outworld-item-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 5px;
}

.outworld-action-panel {
  height: 100%;
  margin-bottom: 5px;
}

.outworld-button {
  color: #337ab7;
}

.outworld-compass-button:hover {
  font-weight: bold;
}

.outworld-status-message .eat-message {
  color: green;
  padding-left: 2px;
  padding-top: 2px;
}

.outworld-status-message .eat-message.bad-action {
  color: red;
}

.outworld-mob-wrapper {
  display: flex;
  flex-direction: row;
}

.outworld-mob-filter-input {
  background-color: #a4aaa8;
  font-weight: bold;
  width: 30px;
  font-size: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.outworld-mob-filter-save-button {
  background-color: #a4aaa8;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 2px;
}

.battle-wrapper {
  margin: 0.5rem;
}

.battle-timer-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.battle-timer-wrapper {
  text-align: center;
  flex-grow: 1;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 2.5;
  vertical-align: middle;
}

.battle-action-button-wrapper {
  flex: 1 1;
  text-align: center;
}

.game-wrapper {
  background: #d2d3d7;
  display: flex;
  flex-flow: row wrap;
}

.game-wrapper > * {
  flex: 1 1 100%;
}

.game-footer {
  background: #b9b9b9;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 5px;
  padding: 10px;
}

.game-main {
  margin: 0 5px;
  text-align: left;
  height: 80vh;
  background: #b9b9b9;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.game-nav {
  height: 80vh;
  overflow-y: hidden;
}

.game-chat {
  background: #b9b9b9;
  padding: 5px;
  height: 80vh;
  border: 1px solid black;
  border-radius: 4px;
}

@media all and (min-width: 600px) {
  .game-nav {
    flex: 0 0 185px;
  }
  .game-chat {
    flex: 0 0 240px;
  }
}

@media all and (min-width: 800px) {
  .game-main    { flex: 3 1; }
  .game-nav { order: 1; }
  .game-main    { order: 2; }
  .game-chat { order: 3; }
  .game-footer  { order: 4; }
}

.underlined {
  text-decoration: underline;
}

.staff {
  font-weight: bold;
}

.staff-1 {
  color: #09b1b1;
}

.staff-2 {
  color: #3e0262;
}

.staff-3 {
  color: #f5f520;
}

.staff-4 {
  color: #d10000;
}

.user-name {
  color: black;
}

.life-safe {
  color: #3b6535;
}

.life-warn {
  color: #f5f520;
}

.life-danger {
  color: #b1400c;
  font-weight: bold;
}

.life-critical {
  color: #ff0000;
  font-weight: bolder;
}

.health {
  display: inline-block;
}

.user-experience {
  color: #3b6535;
}

.align-negative {
  color: #681717
}

.align-positive {
  color: #00ffe4;
}

.user-level {
  color: #3b6535
}

.bolded {
  font-weight: bold;
}

.underlined {
  text-decoration: underline;
}

.status-message-success {
  color: #3b6535;
}

.status-message-fail {
  color: #c80707;
}

.status-message-container {
  height: 18px;
  margin: 5px 0;
}

.game-input {
  background-color: #cfcfcf;
  height: 25px;
  border-radius: 3px;
  border: 1px solid black;
  padding: 0 4px;
  width: 250px;
}

.game-button, .game-button:active {
  background-color: #cfcfcf;
  border: 1px solid black;
  height: 25px;
  margin-top: 9px;
  border-radius: 4px;
}

.game-button:active, .game-button:focus {
  background-color: #cfcfcf;
  border: 1px solid #9e0303;
}

.game-button:hover {
  background-color: #c0bfbf;
  border: 1px solid black;
}

#withdrawButton {
  margin-left: 0;
}

.outworld-event-wrapper {
  outline: 0;
}

.keys-bound.outworld-event-wrapper:focus {
  border: 1px solid red;
}

.inventory-equipped-item {
  color: #9f1818;
}

.inventory-equipment-header {
  margin-top: 5px;
  font-weight: bold;
  text-decoration: underline;
}

.inventory-equipment-link {
  cursor: pointer;
}

.inventory-equipment-link:hover {
  color: #540d0d;
}

.inventory-message {
  height: 20px;
}

.inventory-equipment-section-header {
  background-color: rgb(165, 126, 126);
  border: 1px solid black;
  font-weight: bold;
  text-align: center;
  padding: 2px;
  margin: 5px 0;
}

.stats-stat {
  padding-left: 5px;
}

.stats-stats-header, .stats-equipment-header {
  margin: 5px 0;
}

.stats-character-stats-header, .stats-other-items-header, .stats-clan-header, .stats-house-header {
  margin-top: 15px;
  margin-bottom: 5px;
}

.stats-page-wrapper {
  display: flex;
  flex-direction: row;
}

.stats-left-column {
  order: 1;
  flex: 1 1;
}

.stats-right-column {
  order: 2;
  flex: 1 1;
}

.stats-equipment-name-value {
  color: #5f1414;
}

.stats-equipment-type {
  font-weight: bold;
}

.online-character-row {
  border-bottom: 1px solid black;
}

.news-item {
  margin: 10px 0;
}

.news-item-type {
  font-weight: bold;
}

.news-header {
  font-weight: bold;
  font-size: 1.1em;
}

.inventory-item-enhancements {
  padding-left: 5px;
  display: inline-block;
  color: black;
}

.inventory-enhancement-line {
  display: inline-block;
}

.inventory-enhancement-value {
  text-shadow: 0 0 1px white;
  font-size: 1.2em;
}
.inventory-enhancement-value-1 { color: #907900; }
.inventory-enhancement-value-2 { color: #3b6535; }
.inventory-enhancement-value-3 { color: #dcffc0; }
.inventory-enhancement-value-4 { color: red; }
.inventory-enhancement-value-5 { color: white; }
.inventory-enhancement-value-6 { color: blue; }
.inventory-enhancement-value-7 { color: #4a4b46; }
.inventory-enhancement-value-8 { color: #ba00ff; }

.inventory-enhancement-line {
  position: relative;
}

.ud-tooltip {
  position: absolute;
  margin-top: -40px;
  text-transform: uppercase;
  background-color: #90908d;
  border-radius: 4px;
  padding: 10px;
}

.item-enhancement-wrapper {
  display: inline-block;
}

.enhancement-action-panel {
  position: absolute;
  background-color: #90908d;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  z-index: 5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  margin-left: -40px;
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
}

.account-reset-me-warning {
  color: red;
}

.account-display-message {
  margin-bottom: 5px;
}

.account-error-message {
  color: red;
}

.account-success-message {
  color: green;
}

.profile-tabs, .inventory-tabs {
  display: flex;
}

.profile-tab, .inventory-tab {
  cursor: pointer;
  flex: 1 1;
  text-align: center;
  padding: 5px;
  background-color: gray;
  margin-bottom: 10px;
  color: black;
}

.active-tab {
  background-color: #7d4545;
  font-weight: bold;
  border: 1px solid black;
}

.selected {
  color: #720707;
}

.account-referrals-content {
  display: flex;
}

.account-referrals-generate {
  flex: 1 1;
}

.account-referrals-list {
  width: 80%;
}

.account-active-referral-code {
  display: inline-block;
}

.workout-stats-wrapper {
  margin-top: 1rem;
}

.workout-total-stats {
  color: green;
  margin-top:10px;
}

.workout-total-stats-error {
  color: red;
}

.workout-stat-input {
  background-color: #cfcfcf;
  border-radius: 3px;
  width: 5rem;
}

