.login-container input[type=text], .login-container input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #e1e0e0;
  border-radius: 4px;
}

.login-container button {
  background-color: #625d5d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}

.login-container button:hover {
  background-color: #4d4b4b;
}

.login-container button:hover {
  opacity: 0.8;
}

.login-container .cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #9b271e;
}

.login-container .cancelbtn:hover {
  background-color: #75150d;
}

.login-container {
  padding: 20px;
  background-color: #bdbdbd;
}

.login-container span.psw {
  float: right;
  padding-top: 16px;
}

.login-container.login-footer {
  background-color: #cdcdcd;
}

.psw a {
  cursor: pointer;
}

.psw a:hover {
  text-decoration: none;
}

.login-message {
  color: red;
  text-align: center;
  padding: 5px 0;
  background-color:#552727;
}